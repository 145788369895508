<template>
  <section :class="$style.wrap" v-show="visible">
    <div :class="$style.box" :style="{ width }">
      <slot name="header">
        <div :class="$style.title">
          <span :style="titleStyle">{{ title }}</span>
          <i
            class="TC tc-icon-guanbi1"
            :class="$style.close"
            @click="handleClose"
          />
        </div>
      </slot>
      <div :class="$style.content" :style="{ height: getHeight }">
        <slot name="content" />
      </div>
      <slot name="button">
        <div :class="$style.buttonWrap" v-if="showButton">
          <span :class="$style.button" @click="handleClose">{{
            closeTxt
          }}</span>
          <span
            v-if="okTxt"
            :class="[$style.button, $style.primary]"
            @click="$emit('ok')"
            >{{ okTxt }}</span
          >
        </div>
      </slot>
    </div>
  </section>
</template>
<script>
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
@Component()
export default class ModalBox extends Vue {
  @Prop({ default: false, type: Boolean }) visible;
  @Prop({ default: '提示', type: String }) title;
  @Prop({ default: '关闭', type: String }) closeTxt;
  @Prop({ default: '', type: String }) okTxt;
  @Prop({ default: '500px', type: String }) width;
  @Prop({ default: '', type: String }) height;
  @Prop({ default: true, type: Boolean }) showButton;
  @Prop({ default: () => {}, type: Object }) titleStyle;
  @Emit('close')
  handleClose() {}

  get getHeight() {
    if (this.height) {
      return this.height;
    } else {
      return 'inherit';
    }
  }
}
</script>
<style lang="less" module>
@bgColor: #02051b;
@fontColor: rgba(6, 211, 255, 1);
.wrap {
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 500;
  background: rgba(0, 0, 0, 0.4);
  .box {
    width: 5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(4, 30, 64, 1);
    border-radius: 0.06rem;
    .title {
      text-align: center;
      height: 0.5rem;
      line-height: 0.5rem;
      font-size: 0.16rem;
      color: @fontColor;
      position: relative;
      background-color: rgba(29, 52, 83, 1);
      border-radius: 0.06rem 0.06rem 0 0;
      .close {
        position: absolute;
        font-size: 0.12rem;
        right: 0.21rem;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }
  .content {
    padding: 0.2rem 0;
    color: rgba(6, 211, 255, 1);
    overflow-y: auto;
    scrollbar-color: transparent transparent;
    scrollbar-track-color: transparent;
    -ms-scrollbar-track-color: transparent;
  }
  .buttonWrap {
    text-align: right;
    padding: 0 0.2rem;
    padding-top: 0.12rem;
    padding-bottom: 0.2rem;
    .button {
      width: 0.8rem;
      height: 0.36rem;
      border-radius: 0.05rem;
      background-color: transparent;
      border: 0.01rem solid rgba(6, 212, 255, 1);
      color: rgba(6, 212, 255, 1);
      font-size: 0.14rem;
      justify-content: center;
      align-items: center;
      display: inline-flex;
      cursor: pointer;

      &.primary {
        color: rgba(4, 30, 64, 1);
        background-color: rgba(6, 212, 255, 1);
      }
    }
    .button + .button {
      margin-left: 0.1rem;
    }
  }
}
</style>
