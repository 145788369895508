<template>
  <div :class="$style.warningModal">
    <a-input
      :class="$style.textarea"
      v-model="name"
      placeholder="请输入标题名称"
    />
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
@Component
export default class EditCamera extends Vue {
  @Prop({ type: String, default: '' }) title;
  @Prop({ type: Function }) change;
  /** @name 限制输入的长度 */
  @Prop({ type: Object, default: () => {} }) limit;

  name = '';
  @Watch('title', { immediate: true })
  onTitleChange(value) {
    this.name = value;
  }

  async handleOk() {
    const reg = /[^\u0000-\u00ff]/g;
    if (this.name.replace(reg, 'aa').length > this.limit.length) {
      this.$message.warning(this.limit.label);
      return false;
    }
    if (!this.name) {
      this.$message.warning('标题不能为空！');
      return false;
    }
    this.change(this.name);
    this.close();
  }
  @Emit('close')
  close() {}
}
</script>

<style lang="less" module>
@commonColor: rgba(13, 200, 254, 1);
.warningModal {
  color: #fff;
  padding: 0.2rem;
  :global(.ant-radio) {
    font-size: 0.14rem;
  }
  :global(.ant-radio-inner) {
    width: 0.14rem;
    height: 0.14rem;
    top: -0.01rem;
    border-radius: 1rem;
  }
  :global(.ant-radio-inner::after) {
    background-color: @commonColor;
    top: 0.03rem;
    left: 0.03rem;
    width: 0.06rem;
    height: 0.06rem;
    border-radius: 0.08rem;
  }
  :global(span.ant-radio + *) {
    font-size: 0.14rem;
  }
  :global(label) {
    color: #fff;
  }
  .textarea {
    border-color: @commonColor;
    margin-top: 0.1rem;
    color: #fff;
    font-size: 0.14rem;
    :global(.ant-input) {
      font-size: 0.14rem;
    }
  }
}
</style>
