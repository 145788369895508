var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.modeWrap},[_c('div',{class:_vm.$style.modeBox},[_c('div',{ref:"boxRef"},[_c('div',{class:_vm.$style.header},[(!_vm.infoDisabled)?_c('div',{class:_vm.$style.info},[_c('span',{class:_vm.$style.infoLabel},[_vm._v(_vm._s(_vm.currentCameraData.cameraName)+_vm._s(("" + (_vm.currentCameraData.buildingName ? ("（" + (_vm.currentCameraData.buildingName) + "）") : ''))))]),_c('span',{class:[_vm.$style.infoLabel, _vm.$style.otherLabel]},[_vm._v(_vm._s(_vm.currentCameraData.projectName))])]):_vm._e(),_c('span',{class:_vm.$style.title},[_vm._v(" "+_vm._s(_vm.title)+" "),(!_vm.isFullscreen)?_c('i',{staticClass:"TC tc-icon-xiugai1",class:_vm.$style.icon,on:{"click":function($event){$event.stopPropagation();return _vm.handleEditScreenName.apply(null, arguments)}}}):_vm._e()]),_c('div',{class:_vm.$style.operation},[(
              _vm.resultList[_vm.currentModel] && _vm.resultList[_vm.currentModel].isPublic
            )?_c('i',{staticClass:"TC tc-icon-share-alt",class:_vm.$style.otherIcon,on:{"click":_vm.handleShareView}}):_vm._e(),(_vm.hasView)?_c('i',{staticClass:"TC tc-icon-shezhi",class:_vm.$style.otherIcon,on:{"click":_vm.handleEditView}}):_vm._e(),_c('i',{staticClass:"TC",class:[
              _vm.$style.otherIcon,
              _vm.isFullscreen ? 'tc-icon-huanyuanhuabu' : 'tc-icon-quanping' ],on:{"click":_vm.handleFullScreen}})])]),_c('div',{class:[
          _vm.$style.main,
          _vm.isFullscreen ? _vm.$style.mainFullScreen : '',
          _vm.hasView ? '' : _vm.$style.mainHasView ]},[_c('div',{class:[
            _vm.$style.videoBox,
            _vm.model.label === 'MODEL_FOUR' ? _vm.$style.fourBox : '',
            _vm.model.label === 'MODEL_NINE' ||
            _vm.model.label === 'MODEL_LEFT' ||
            _vm.model.label === 'MODEL_RIGHT'
              ? _vm.$style.nineBox
              : '',
            _vm.model.label === 'MODEL_TWELVE' || _vm.model.label === 'MODEL_MAIN'
              ? _vm.$style.twelveBox
              : '' ]},[(!_vm.hasView)?_c('video-box',{ref:"refDefaultBox"},[_c('div',{class:_vm.$style.defaultBox},[_c('img',{class:_vm.$style.image,style:({
                  width: ((_vm.image.width) + "px"),
                  height: ((_vm.image.height) + "px"),
                }),attrs:{"src":require("@/assets/images/tishi.png")}}),_c('div',{class:_vm.$style.descInfo},[_c('p',{class:_vm.$style.info},[_vm._v("开始添加自己的监控大屏模式！")]),(_vm.$p.action('CREATE', '/iot/cameraView'))?_c('router-link',{class:_vm.$style.btn,attrs:{"tag":"a","to":"/iot/editCameraView"}},[_vm._v("添加")]):_vm._e()],1)])]):_vm._e(),_vm._l((_vm.cameras),function(item,index){return _c('video-box',{key:item.id,staticClass:"camera-video-box",class:[
              _vm.model.label === 'MODEL_MAIN' && index === 0
                ? _vm.$style.boxOnePointer
                : '',
              _vm.model.label === 'MODEL_LEFT' && index === 0
                ? _vm.$style.boxOneLeftMain
                : '',
              _vm.model.label === 'MODEL_RIGHT' && index === 0
                ? _vm.$style.boxOneRightMain
                : '' ]},[(item.url)?_c('video-view',{attrs:{"url":item.url,"accessToken":item.token,"data":item}}):_vm._e()],1)})],2)])]),(_vm.hasView)?_c('div',{class:_vm.$style.footer},[_vm._l((_vm.resultList),function(item,index){return _c('span',{key:'item' + index,class:[_vm.$style.btn, _vm.currentModel === index ? _vm.$style.actived : ''],on:{"click":function($event){$event.stopPropagation();return _vm.handleChangeModel(index)}}},[_vm._v(_vm._s(item.modelName))])}),(!_vm.hasDeleted)?_c('div',[(_vm.$p.action('DELETE', '/iot/cameraView'))?_c('span',{class:_vm.$style.button,on:{"click":_vm.handleDeleteMode}},[_vm._v("删除")]):_vm._e(),(_vm.$p.action('CREATE', '/iot/cameraView'))?_c('span',{class:_vm.$style.button,attrs:{"to":"/iot/editCameraView"},on:{"click":_vm.handleAddMode}},[_vm._v("添加")]):_vm._e()]):_c('div',[_c('span',{class:_vm.$style.button,on:{"click":_vm.handleOKDelete}},[_vm._v("保存")]),_c('span',{class:_vm.$style.button,on:{"click":_vm.handleCancelDelete}},[_vm._v("取消")])])],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }