import request from '../request';
const serviceName = '/monitor/cameraViewList';
/**
 * @name 获取摄像头列表
 */
export function getCameraViewList(data) {
  return request(`${serviceName}/cameraList`, {
    body: data,
  });
}
/**
 * @name 获取用户监控视图模式
 */
export function getCameraViewMode(data) {
  return request(`${serviceName}/getCameraViewMode`, {
    body: data,
  });
}
/**
 * @name 保存用户监控视图模式
 */
export function saveCameraViewMode(data) {
  return request(`${serviceName}/saveCameraViewMode`, {
    body: data,
    method: 'POST',
  });
}
/**
 * @name 删除模式
 */
export function deleteMode(id) {
  return request(`${serviceName}/deleteMode/${id}`, {
    method: 'POST',
  });
}
/**
 * @name 删除视图共享
 */
export function deleteViewPublic(id) {
  return request(`${serviceName}/deleteViewPublic/${id}`, {
    method: 'DELETE',
    closeMessage: true,
  });
}
/**
 * @name 保存-编辑监控视图大屏名称
 */
export function saveCameraViewScreenName(screenName) {
  return request(`${serviceName}/saveCameraViewScreenName/${screenName}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
  });
}
/**
 * @name 新增视图共享
 */
export function saveViewPublic(data) {
  return request(`${serviceName}/saveViewPublic`, {
    method: 'POST',
    body: data,
  });
}
/**
 * @name 新增视图分享
 */
export function shareCameraView(data) {
  return request(`${serviceName}/shareCameraView`, {
    method: 'POST',
    body: data,
  });
}
/**
 * @name 获取视图共享列表
 */
export function listViewPublic(data) {
  return request(`${serviceName}/listViewPublic`, {
    body: data,
    method: 'POST',
  });
}
/**
 * @name 获取监控视图大屏名称
 */
export function getCameraViewScreenName() {
  return request(`${serviceName}/getCameraViewScreenName`);
}
/**
 * @name 获取用户监控视图模式详情
 */
export function getCameraViewModelDetails(id) {
  return request(`${serviceName}/getCameraViewModelDetails/${id}`);
}
/**
 * @name 获取用户【共享】监控视图模式详情
 */
export function getViewPublicDetails(id) {
  return request(`${serviceName}/getViewPublicDetails/${id}`);
}
/**
 * @name 获取用户【共享】视图分享 or 检查【监控视图】
 */
export function getShareCameraViewMode(data) {
  return request(`${serviceName}/getShareCameraViewMode`, {
    auth: false,
    body: data,
    closeMessage: true,
  });
}
