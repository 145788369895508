<template>
  <div :class="$style.wrap">
    <video-model />
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import VideoModel from './components/show/video-model.vue';
import { flexDpr } from '@/assets/js/flex-dpr.js';

flexDpr();

@Component({
  components: {
    VideoModel,
  },
})
export default class CameraView extends Vue {
  mounted() {}
}
</script>
<style lang="less" module>
.wrap {
}
</style>
