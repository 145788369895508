import Vue from 'vue';
import classNames from 'classnames';
import Modal from './modal.vue';
import { createVNode } from '@/utils';

export default function createModal(Component, { className, ...modalProps }) {
  const dom = document.createElement('div');
  let box = null;
  if (modalProps.boxEl) {
    box = modalProps.boxEl;
  } else {
    box = document.body;
  }
  box.appendChild(dom);
  const vm = new Vue({
    el: dom,
    data() {
      return {
        visible: true,
        modalProps,
      };
    },
    methods: {
      handleClose() {
        this.visible = false;
        this.$destroy();
        this.$el.parentElement.removeChild(this.$el);
      },
      handleOk() {
        this.visible = false;
        modalProps.ok && modalProps.ok();
      },
    },
    render(h) {
      const modalClass = classNames('create-modal-wrapper', className);
      return (
        <Modal
          visible={this.visible}
          onClose={this.handleClose}
          onOk={this.handleOk}
          {...{ props: this.modalProps, class: modalClass }}
        >
          <template slot="content">
            {createVNode(h, Component, 'body')}
          </template>
        </Modal>
      );
    },
  });
  return vm;
}
