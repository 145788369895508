<template>
  <div :class="$style.modeWrap">
    <div :class="$style.modeBox">
      <div ref="boxRef">
        <div :class="$style.header">
          <div :class="$style.info" v-if="!infoDisabled">
            <span :class="$style.infoLabel"
              >{{ currentCameraData.cameraName
              }}{{
                `${
                  currentCameraData.buildingName
                    ? `（${currentCameraData.buildingName}）`
                    : ''
                }`
              }}</span
            >
            <span :class="[$style.infoLabel, $style.otherLabel]">{{
              currentCameraData.projectName
            }}</span>
          </div>
          <span :class="$style.title">
            {{ title }}
            <i
              v-if="!isFullscreen"
              :class="$style.icon"
              class="TC tc-icon-xiugai1"
              @click.stop="handleEditScreenName"
            ></i>
          </span>
          <div :class="$style.operation">
            <i
              class="TC tc-icon-share-alt"
              :class="$style.otherIcon"
              @click="handleShareView"
              v-if="
                resultList[currentModel] && resultList[currentModel].isPublic
              "
            />
            <i
              class="TC tc-icon-shezhi"
              :class="$style.otherIcon"
              @click="handleEditView"
              v-if="hasView"
            />
            <i
              class="TC"
              :class="[
                $style.otherIcon,
                isFullscreen ? 'tc-icon-huanyuanhuabu' : 'tc-icon-quanping',
              ]"
              @click="handleFullScreen"
            />
          </div>
        </div>
        <div
          :class="[
            $style.main,
            isFullscreen ? $style.mainFullScreen : '',
            hasView ? '' : $style.mainHasView,
          ]"
        >
          <div
            :class="[
              $style.videoBox,
              model.label === 'MODEL_FOUR' ? $style.fourBox : '',
              model.label === 'MODEL_NINE' ||
              model.label === 'MODEL_LEFT' ||
              model.label === 'MODEL_RIGHT'
                ? $style.nineBox
                : '',
              model.label === 'MODEL_TWELVE' || model.label === 'MODEL_MAIN'
                ? $style.twelveBox
                : '',
            ]"
          >
            <video-box ref="refDefaultBox" v-if="!hasView">
              <div :class="$style.defaultBox">
                <img
                  :style="{
                    width: `${image.width}px`,
                    height: `${image.height}px`,
                  }"
                  :class="$style.image"
                  src="~@/assets/images/tishi.png"
                />
                <div :class="$style.descInfo">
                  <p :class="$style.info">开始添加自己的监控大屏模式！</p>
                  <router-link
                    v-if="$p.action('CREATE', '/iot/cameraView')"
                    tag="a"
                    to="/iot/editCameraView"
                    :class="$style.btn"
                    >添加</router-link
                  >
                </div>
              </div>
            </video-box>
            <video-box
              class="camera-video-box"
              :class="[
                model.label === 'MODEL_MAIN' && index === 0
                  ? $style.boxOnePointer
                  : '',
                model.label === 'MODEL_LEFT' && index === 0
                  ? $style.boxOneLeftMain
                  : '',
                model.label === 'MODEL_RIGHT' && index === 0
                  ? $style.boxOneRightMain
                  : '',
              ]"
              v-for="(item, index) in cameras"
              :key="item.id"
            >
              <video-view
                v-if="item.url"
                :url="item.url"
                :accessToken="item.token"
                :data="item"
              />
            </video-box>
          </div>
        </div>
      </div>
      <div v-if="hasView" :class="$style.footer">
        <span
          :class="[$style.btn, currentModel === index ? $style.actived : '']"
          v-for="(item, index) in resultList"
          :key="'item' + index"
          @click.stop="handleChangeModel(index)"
          >{{ item.modelName }}</span
        >
        <div v-if="!hasDeleted">
          <span
            v-if="$p.action('DELETE', '/iot/cameraView')"
            :class="$style.button"
            @click="handleDeleteMode"
            >删除</span
          >
          <span
            v-if="$p.action('CREATE', '/iot/cameraView')"
            :class="$style.button"
            @click="handleAddMode"
            to="/iot/editCameraView"
            >添加</span
          >
        </div>
        <div v-else>
          <span :class="$style.button" @click="handleOKDelete">保存</span>
          <span :class="$style.button" @click="handleCancelDelete">取消</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';
import VideoBox from './video-box.vue';
import VideoView from '@/components/video-view';
import { emitter, CAMETA_VIEW_SHOW, MODEL } from '../edit/final';
import screenfull from '@/utils/fullscreen';
import {
  getCameraViewMode,
  deleteMode,
  deleteViewPublic,
  saveCameraViewScreenName,
  getCameraViewScreenName,
} from '@/services/monitor/camera-view-list';
import EditTitle from './edit-title.vue';
import createModal from '@/views/iot/screen/components/message/modal.js';
import Tip from '@/components/tip/index';

@Component({
  components: {
    VideoBox,
    VideoView,
  },
})
export default class VideoModel extends Vue {
  image = {
    width: 0,
    height: 0,
  };
  hasView = true;
  mounted() {
    this.handleKeyEscEvent();
    emitter.on(CAMETA_VIEW_SHOW, this.handleCameraViewShow);
    this.fetchData();
  }

  fetchRefDefaultBoxClientRect() {
    this.$nextTick(() => {
      const RECT = this.$refs?.refDefaultBox.$el.getBoundingClientRect();
      this.image.width = 0.35 * RECT.width;
      this.image.height = 0.42 * RECT.height;
    });
  }
  @Watch('hasView', { immediate: true })
  onHasViewChange(value) {
    if (!value) {
      this.fetchRefDefaultBoxClientRect();
    }
  }

  resultList = [];
  MODEL = MODEL;
  currentModel = 0;
  handleChangeModel(index) {
    this.currentModel = index;
  }
  async fetchData() {
    const result = await getCameraViewMode();
    this.resultList = result ? result : [];

    if (this.resultList && this.resultList.length) {
      this.hasView = true;
    } else {
      this.hasView = false;
    }

    this.title = await getCameraViewScreenName();
    if (!this.title) {
      this.title = '监控大屏';
    }
  }
  get modelList() {
    return this.resultList[this.currentModel]?.model || [];
  }
  get currentModelItem() {
    return this.resultList.length > 0 ? this.resultList[this.currentModel] : {};
  }
  get model() {
    const label = this.resultList[this.currentModel]?.modelLabel || '';
    let index = -1;
    Object.values(MODEL).forEach((item, itemIndex) => {
      if (item.label === label) {
        index = itemIndex;
      }
    });
    return index === -1 ? { lable: '', name: '', length: 0 } : MODEL[index];
  }
  get cameras() {
    const array = [];
    for (let i = 0; i < this.model.length; i++) {
      array.push({
        id: uuidv4(),
      });
    }
    this.modelList.forEach(item => {
      array[item.number] = {
        ...array[item.number],
        ...item,
        // url: 'ezopen://WSDRAB@open.ys7.com/F30203581/1.hd.live',
        // token:
        //   'at.6wz0zt03d4tifanxdmh44qgn7o47q41t-968n2v5ib7-00xwbtq-foadirsso',
      };
    });
    return array;
  }

  infoDisabled = true;
  currentCameraData = {};
  handleCameraViewShow(item) {
    this.currentCameraData = item.data;
    if (item.model === 'enter') {
      this.infoDisabled = false;
    } else {
      this.infoDisabled = true;
    }
  }

  isFullscreen = false;
  handleFullScreen() {
    const id = this.$refs.boxRef;
    if (screenfull.element === id && screenfull.isFullscreen) {
      screenfull.exit();
      this.isFullscreen = false;
    } else {
      screenfull.request(id);
      this.isFullscreen = true;
    }
  }
  handleKeyEscEvent() {
    document.addEventListener('fullscreenchange', () => {
      if (!screenfull.isFullscreen && this.isFullscreen) {
        this.isFullscreen = false;
      }
    });
  }

  handleEditView() {
    this.$router.push(
      `/iot/editCameraView?i=${this.currentModelItem.modelNumber}`,
    );
  }

  handleShareView() {
    createModal(
      () => (
        <Tip>
          <span slot="txt" style="color: #ffffff;">
            当前摄像头已共享到“监控视图”列表
          </span>
          <span slot="subTxt" style="color: #999999;">
            是否要取消共享？
          </span>
        </Tip>
      ),
      {
        title: '操作提示',
        width: this.$size(470),
        closeTxt: '取消',
        okTxt: '停止共享',
        ok: async () => {
          await deleteViewPublic(
            this.resultList[this.currentModel].viewPublicId,
          );
          this.$message.success({
            content: '停止共享成功！',
            duration: 0.2,
            onClose: () => {
              this.resultList[this.currentModel].viewPublicId = '';
              this.resultList[this.currentModel].isPublic = false;
            },
          });
        },
      },
    );
  }

  hasDeleted = false;
  handleDeleteMode() {
    this.hasDeleted = true;
  }
  handleAddMode() {
    const MAX_LENGTH = 10;
    if (this.resultList.length >= MAX_LENGTH) {
      this.$message.warning(`最多支持创建${MAX_LENGTH}个自定义布局模式！`);
      return false;
    } else {
      this.$router.push('/iot/editCameraView');
    }
  }
  handleCancelDelete() {
    this.hasDeleted = false;
  }
  async handleOKDelete() {
    try {
      const modelNumber = this.resultList[this.currentModel].modelNumber;
      await deleteMode(modelNumber);
      this.handleCancelDelete();
      await this.fetchData();
    } catch (error) {
      return false;
    }
  }

  editable = true;
  title = '';
  handleEditScreenName() {
    createModal(
      () => (
        <EditTitle
          ref={'EditRef'}
          limit={{ length: 64, label: '标题最长不超过32汉字/64英文字符长度' }}
          title={this.title}
          change={name => this.changeTitle(name)}
        />
      ),
      {
        title: '编辑监控大屏名称',
        className: this.$style.warningModal,
        closeTxt: '取消',
        okTxt: '确定',
        ok: () => {
          this.$refs.EditRef && this.$refs.EditRef.handleOk();
        },
      },
    );
  }
  async changeTitle(name) {
    this.title = name;
    if (this.title) {
      await saveCameraViewScreenName(this.title);
    }
  }
}
</script>
<style lang="less" module>
@bgColor: #02051b;
@fontColor: rgba(5, 211, 255, 1);
@lineColor5: rgba(13, 200, 254, 0.5);
@lineColor: rgba(13, 200, 254, 1);
@lightLineColor: rgba(13, 200, 254, 0.5);
@boxshadow: 0px 0px 18px 2px rgba(12, 181, 232, 0.4) inset;
.modeWrap {
  background: @bgColor;
  width: 100%;
  height: 100vh;
  padding: 0.15rem;
  box-sizing: border-box;

  .modeBox {
    border: 0.01rem solid @fontColor;
  }

  .header {
    height: 0.79rem;
    border-bottom: 0.01rem solid @lineColor5;
    position: relative;
    .info {
      position: absolute;
      left: 20px;
      top: 18px;
      .infoLabel {
        font-size: 0.18rem;
        display: block;
        color: #ffffff;
      }
      .otherLabel {
        color: #ffffff;
        font-size: 0.14rem;
        padding-top: 0.05rem;
      }
    }
    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      padding: 0 0.4rem;
      font-size: 0.36rem;
      color: @fontColor;
      text-align: center;
      &:hover {
        .icon {
          display: inline-block;
        }
      }
      .icon {
        display: none;
      }
    }
    .operation {
      position: absolute;
      right: 0.2rem;
      top: 50%;
      transform: translateY(-50%);
      .otherIcon {
        font-size: 0.2rem;
        color: #fff;
        cursor: pointer;
      }
      .otherIcon + .otherIcon {
        margin-left: 0.3rem;
      }
    }
  }

  .main {
    height: calc(100vh - 1.6rem);
    padding: 0.2rem;
    &.mainFullScreen {
      height: calc(100vh - 0.8rem);
    }
    &.mainHasView {
      height: calc(100vh - 1.2rem);
    }
    .videoBox {
      display: grid;
      grid-row-gap: 0.2rem;
      grid-column-gap: 0.2rem;
      height: 100%;
      // 四分屏
      &.fourBox {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
      }
      // 九分屏
      &.nineBox {
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
      }
      // 十二分屏
      &.twelveBox {
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      &.oneBox {
        height: 100%;
        width: 100%;
      }
      // 焦点模式
      .boxOnePointer {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
      }
      // 主次模式-左大
      .boxOneLeftMain {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 4;
      }
      // 主次模式-右大
      .boxOneRightMain {
        grid-column-start: 2;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 4;
      }
    }

    .defaultBox {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .descInfo {
        text-align: center;
        .info {
          margin-top: 0.3rem;
          font-size: 0.14rem;
        }
        .btn {
          cursor: pointer;
          display: block;
          margin: auto;
          font-size: 0.14rem;
          margin-top: 0.19rem;
          width: 0.9rem;
          height: 0.32rem;
          line-height: 0.32rem;
          text-align: center;
          color: #fff;
          background-color: @fontColor;
          border-radius: 0.04rem;
        }
      }
    }
  }

  .footer {
    height: 0.7rem;
    line-height: 0.7rem;
    margin-top: -0.2rem;
    display: flex;
    align-items: center;
    .btn {
      display: inline-block;
      padding: 0.08rem 0.14rem;
      text-align: center;
      height: 0.32rem;
      line-height: 1;
      background-color: #ffffff;
      margin-left: 0.2rem;
      color: @fontColor;
      border-radius: 0.04rem;
      cursor: pointer;
      font-size: 0.16rem;

      &.actived {
        color: #fff;
        background-color: @fontColor;
      }
    }
    .button {
      margin-left: 0.2rem;
      color: @fontColor;
      cursor: pointer;
      font-size: 0.16rem;
    }
  }
}
.warningModal {
  :global(.ant-modal-body) {
    padding-top: 0.15rem;
    margin-bottom: 0.15rem;
  }
  :global(.ant-modal-content) {
    background-color: @bgColor;
    border-radius: unset;
  }
  :global(.ant-modal-header) {
    color: @fontColor;
    background: linear-gradient(
      90deg,
      rgba(5, 211, 255, 0.2) 0%,
      transparent 98%
    );
    border: 1px solid @lightLineColor;
    box-shadow: @boxshadow;
    padding: 0.1rem 0.14rem;
    font-size: 0.14rem;
    border-radius: unset;
  }
  :global(.ant-modal-title) {
    color: @fontColor;
    font-weight: 200;
    font-size: 0.14rem;
    text-align: left;
  }
  :global(.ant-modal-close-x) {
    width: 0.4rem;
    height: 0.34rem;
    line-height: 0.34rem;
    color: @fontColor;
    font-size: 0.14rem;
    padding: 0.05rem 0.14rem;
  }
  :global(.ant-modal-close-x .anticon) {
    vertical-align: unset;
  }
}
</style>
