<template>
  <div :class="$style.videoBox" ref="refDefaultBox">
    <slot></slot>
    <div :class="$style.defaultBox" v-if="defaultBox && !$slots.default">
      <img
        :style="{
          width: `${image.width}px`,
          height: `${image.height}px`,
        }"
        :class="$style.image"
        src="~@/assets/images/tishi.png"
      />
      <div :class="$style.descInfo">
        <p :class="$style.info">从右侧列表拖拽到此处</p>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { emitter, CAMETA_SCREEN_MODEL } from '../edit/final';
@Component()
export default class VideoBox extends Vue {
  @Prop({ type: Boolean, default: false }) defaultBox;

  image = {
    width: 0,
    height: 0,
  };
  mounted() {
    this.initDefault();
    emitter.on(CAMETA_SCREEN_MODEL, this.initDefault);
  }

  initDefault() {
    this.$nextTick(() => {
      const RECT = (this.$refs?.refDefaultBox &&
        this.$refs.refDefaultBox.getBoundingClientRect()) || {
        width: 0,
        height: 0,
      };
      this.image.width = 0.35 * RECT.width;
      this.image.height = 0.42 * RECT.height;
    });
  }
}
</script>
<style lang="less" module>
@fontColor: rgba(5, 211, 255, 1);
.videoBox {
  box-shadow: 0 0 0.18rem 0.02rem rgba(12, 181, 232, 0.4) inset;
  background: linear-gradient(@fontColor, @fontColor) left top,
    linear-gradient(@fontColor, @fontColor) left top,
    linear-gradient(@fontColor, @fontColor) right top,
    linear-gradient(@fontColor, @fontColor) right top,
    linear-gradient(@fontColor, @fontColor) left bottom,
    linear-gradient(@fontColor, @fontColor) left bottom,
    linear-gradient(@fontColor, @fontColor) right bottom,
    linear-gradient(@fontColor, @fontColor) right bottom;
  background-repeat: no-repeat;
  border-width: 0.04rem;
  background-size: 0.02rem 0.2rem, 0.2rem 0.02rem;
  // position: relative;
  .defaultBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    .descInfo {
      text-align: center;
      .info {
        margin-top: 0.3rem;
        font-size: 0.14rem;
      }
      .btn {
        cursor: pointer;
        display: block;
        margin: auto;
        font-size: 0.14rem;
        margin-top: 0.19rem;
        width: 0.9rem;
        height: 0.32rem;
        line-height: 0.32rem;
        text-align: center;
        color: #fff;
        background-color: @fontColor;
        border-radius: 0.04rem;
      }
    }
  }
}
</style>
